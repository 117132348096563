import { Link, useRouteError } from "react-router-dom";

const ErrorHandler = () => {
  const error = useRouteError();
  console.log(error);

  const ERROR_STATUS_MESSAGE_MAP = {
    404: "Page not found! Please check the link or go back to home page.",
    500: "Sorry, an unexpected error has occurred. Please try again.",
  };

  return (
    <div id="error-page" className="padding-scalar-32">
      <div className="padding-scalar-y-64">
        <h1>Uh oh! 😰</h1>
        <p>{ERROR_STATUS_MESSAGE_MAP[error?.status || 500]}</p>
      </div>

      <Link className="plain-link button" to={"/"}>
        Go back!
      </Link>
    </div>
  );
};

export default ErrorHandler;
