import React from "react";
import Carousel from "../../../components/Carousel";
import projectData from "../../../data/projects.json";

const ProjectsSection = () => {
  const projectRenderer = (project) => (
    <div className="card" key={`card_project_${project.key}`}>
      <div className="card-header">
        <h3 className="color-theme-darker margin-scalar-y-8">
          {project.title}
        </h3>
        <small className="color-theme-black">{project.duration}</small>
      </div>
      <div className="card-body">
        <p>{project.description}</p>
        <p className="color-theme-light">
          <strong>Categories</strong>: {project.categories?.join(", ")}
        </p>
      </div>
      <div className="card-footer color-theme-light">
        <strong>Stack: </strong>
        <span>{project.stack}</span>
      </div>
    </div>
  );

  return (
    <section
      id="projects-section"
      className="section section-dark section-span section-align-center"
    >
      <div className="container">
        <h2 className="text-align-center">Projects</h2>

        <Carousel
          carouselId="projects"
          carouselItems={projectData}
          carouselItemRenderer={projectRenderer}
        />
      </div>
    </section>
  );
};

export default ProjectsSection;
