import React, { useEffect, useState } from "react";
import {
  getActiveStateClass,
  joinClasses,
} from "../../utils/elementClassUtils";
import "./Navigation.scss";
import BrandIcon from "../../assets/images/saga.svg";
import useWindowResize from "../../hooks/windowResize";

const Navigation = () => {
  const menuBarClasses = ["top", "middle", "bottom"];
  const navigationMenuItems = [
    { link: "/#about-section", label: "About" },
    { link: "/#skills-section", label: "Skills" },
    { link: "/#work-section", label: "Work" },
    { link: "/#projects-section", label: "Projects" },
    { link: "/#resume-section", label: "Resume" },
    { link: "/#contact-section", label: "Contact" },
  ];
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState(false);

  const windowSize = useWindowResize();

  useEffect(() => {
    setIsNavigationMenuOpen(false);
  }, [windowSize]);

  return (
    <nav className="app-navigation">
      <a className="plain-link" href="/">
        <img
          className="app-navigation-brand-icon"
          src={BrandIcon}
          alt="brand-icon"
        />
      </a>
      <div
        className={joinClasses([
          "app-navigation-menu-icon",
          getActiveStateClass(isNavigationMenuOpen),
        ])}
        onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}
      >
        {menuBarClasses.map((menuBarClass) => (
          <div
            className={`app-navigation-menu-icon-bar ${menuBarClass}`}
            key={menuBarClass}
          />
        ))}
      </div>

      <div
        className={joinClasses([
          "app-navigation-menu",
          isNavigationMenuOpen ? "show" : "",
        ])}
      >
        {navigationMenuItems.map((navigationMenuItem) => (
          <a
            key={navigationMenuItem.link}
            href={navigationMenuItem.link}
            className="app-navigation-menu-item plain-link"
            onClick={() => setIsNavigationMenuOpen(false)}
          >
            {navigationMenuItem.label}
          </a>
        ))}
      </div>
    </nav>
  );
};

export default Navigation;
