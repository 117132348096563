import React from "react";
import "./blog.scss";
import { useDatabaseQuery } from "../../database/client";

const BlogView = () => {
  const { data: notebooks } = useDatabaseQuery({ tablename: "ref_notebooks" });

  return (
    <div id="blog-view" className="view">
      <section className="section section-span text-align-left">
        <h1>All reads</h1>
        {notebooks === null ? (
          "Loading"
        ) : (
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>
              {notebooks?.map((notebook, index) => (
                <tr key={notebook?.id}>
                  <td>{index + 1}</td>
                  <td>{notebook.title}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    </div>
  );
};

export default BlogView;
