import React from "react";
import "./IframeContainer.scss";

const IframeContainer = ({
  id = null,
  iframeTitle = null,
  iframeSource = null,
  iframeWidth = "100%",
  iframeHeight = null,
  iframeClassName = null,
  className = null,
  showIframe = true,
}) => {
  return (
    <div
      id={id}
      className={`iframe-container ${className}`}
      iframe-shown={String(showIframe)}
    >
      {showIframe && (
        <iframe
          className={iframeClassName}
          title={iframeTitle}
          src={iframeSource}
          width={iframeWidth}
          height={iframeHeight}
        />
      )}
    </div>
  );
};

export default IframeContainer;
