import { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";

export const db = createClient(
  process.env.REACT_APP_SUPABASE_API_URL,
  process.env.REACT_APP_SUPABASE_API_KEY
);

export const useDatabaseQuery = ({ tablename = null }) => {
  const [queryData, setQueryData] = useState(null);

  useEffect(() => {
    const fetchQueryData = async () => {
      if (!queryData) {
        const response = await db.from(tablename).select();
        setQueryData(response?.data);
      }
    };

    fetchQueryData();
  }, [queryData, tablename]);

  return { data: queryData };
};
