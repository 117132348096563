import React from "react";
import ProfilePicture from "../../../assets/images/Identity.png";
import { joinClasses } from "../../../utils/elementClassUtils";
import IframeContainer from "../../../components/IframeContainer";

const AboutSection = () => {
  return (
    <section
      id="about-section"
      className="section section-span section-align-center"
    >
      <div className="container margin-scalar-top-auto">
        <div id="about-section-intro-card">
          <div id="about-section-intro-card-profile-picture">
            <img
              src={ProfilePicture}
              alt="profile"
              className="profile-picture"
            />
          </div>
          <div
            id="about-section-intro-card-details"
            className="padding-scalar-32"
          >
            <h1
              className={joinClasses([
                "brand-name",
                "color-theme-light",
                "margin-scalar-y-16",
              ])}
            >
              Sarthak Gambhir
            </h1>
            <p className="font-size-xl font-code margin-scalar-0">
              A software developer, solution designer, maker, and an automation
              enthusiast!
            </p>
          </div>
        </div>
      </div>

      <IframeContainer
        className={"margin-scalar-top-auto"}
        iframeTitle={"Interactive Resume - Sarthak Gambhir"}
        iframeSource={"https://itch.io/embed/2352880"}
        iframeWidth={"600px"}
        iframeClassName={"margin-scalar-x-auto"}
      />
    </section>
  );
};

export default AboutSection;
