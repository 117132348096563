import React, { useCallback, useEffect, useState } from "react";
import "./Carousel.scss";
import useWindowResize from "../../hooks/windowResize";

const Carousel = ({
  carouselId = "default",
  carouselItems = [],
  carouselItemRenderer = null,
  autoSlide = true,
  autoSlideInterval = 5,
  enablePauseControl = true,
}) => {
  // State manager for current item index
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  // State manager for automatic slide mode
  const [isAutoSlideEnabled, setIsAutoSlideEnabled] = useState(autoSlide);
  // Initialize  ook
  const windowSize = useWindowResize();

  // Utility method to correct index based on value
  // TODO: Extract to be utilities
  const getValidItemIndex = useCallback(
    (value = 0) => {
      if (value >= 0) {
        if (value < carouselItems.length) {
          return value;
        } else {
          return value % carouselItems.length;
        }
      } else {
        return carouselItems.length - (Math.abs(value) % carouselItems.length);
      }
    },
    [carouselItems]
  );

  // Callback to slide to current item in the carousel
  const slideToCarouselItem = useCallback(
    (itemIndex = 0) => {
      const carouselItemContainer = document.getElementById(
        `carousel-item-container-${carouselId}`
      );
      const currentCarouselItem = document.getElementById(
        `carousel-item-${carouselItems[itemIndex].key}`
      );
      carouselItemContainer.scrollTo({
        left: currentCarouselItem.offsetLeft,
        top: 0,
        behavior: "auto",
      });
    },
    [carouselId, carouselItems]
  );

  // On change of currentItemIndex, slide to new carousel item
  useEffect(() => {
    slideToCarouselItem(currentItemIndex);
  }, [currentItemIndex, slideToCarouselItem]);

  // On window resize return to original item position.
  useEffect(() => {
    slideToCarouselItem(currentItemIndex);
  }, [windowSize, currentItemIndex, slideToCarouselItem]);

  // On auto slide timeout, if auto slide is enabled,
  // slide to next item in carousel
  useEffect(() => {
    let timeoutId = null;

    if (isAutoSlideEnabled) {
      timeoutId = setTimeout(
        () => setCurrentItemIndex(getValidItemIndex(currentItemIndex + 1)),
        autoSlideInterval * 1000
      );
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    currentItemIndex,
    isAutoSlideEnabled,
    setCurrentItemIndex,
    getValidItemIndex,
    autoSlideInterval,
  ]);

  // Block default scroll events of mouse and touch
  const preventDefault = (e) => {
    e.preventDefault();
    slideToCarouselItem(currentItemIndex);
  };

  return (
    <div className="carousel" id={`carousel-${carouselId}`}>
      <div
        id={`carousel-item-container-${carouselId}`}
        className="carousel-item-container"
        onScroll={preventDefault}
        onTouchMove={preventDefault}
      >
        {carouselItems.map((carouselItem) => (
          <div
            className="carousel-item"
            id={`carousel-item-${carouselItem.key}`}
            key={carouselItem.key}
          >
            {carouselItemRenderer ? carouselItemRenderer(carouselItem) : null}
          </div>
        ))}
      </div>

      <div className="carousel-interface">
        <div className="carousel-state">
          <p>
            {currentItemIndex + 1}/{carouselItems.length}
          </p>
        </div>
        <div className="carousel-controls">
          <button
            className="carousel-item-button first-item-button"
            onClick={() => setCurrentItemIndex(getValidItemIndex(0))}
            title="Go to start"
          >
            l◀
          </button>
          <button
            className="carousel-item-button previous-item-button"
            onClick={() =>
              setCurrentItemIndex(getValidItemIndex(currentItemIndex - 1))
            }
            title="Previous"
          >
            ❮
          </button>

          {enablePauseControl && (
            <button
              className="carousel-item-button pause-auto-slide-button"
              onClick={() => setIsAutoSlideEnabled(!isAutoSlideEnabled)}
              title={isAutoSlideEnabled ? "Stop" : "Play"}
            >
              {isAutoSlideEnabled ? "l l" : "▶"}
            </button>
          )}

          <button
            className="carousel-item-button next-item-button"
            onClick={() =>
              setCurrentItemIndex(getValidItemIndex(currentItemIndex + 1))
            }
            title="Next"
          >
            ❯
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
