import React from "react";
import skillsData from "../../../data/skills.json";
import ResponsiveWebDesign from "../../../components/AnimatedImages/ResponsiveWebDesign/ResponsiveWebDesign";
import FullStackDesign from "../../../components/AnimatedImages/FullStackDesign/FullStackDesign";
import AutomationSolutionDesign from "../../../components/AnimatedImages/AutomationSolutionDesign/AutomationSolutionDesign";

const SkillsSection = () => {
  const skillImageMap = {
    FullStackDesign: <FullStackDesign />,
    AutomationSolutionDesign: <AutomationSolutionDesign />,
    ResponsiveWebDesign: <ResponsiveWebDesign />,
  };

  return (
    <section
      id="skills-section"
      className="section section-dark section-span section-align-center"
    >
      <div className="container">
        <h2 className="text-align-center">What I do!</h2>
        <div className="card-row">
          {skillsData.map((skill) => (
            <div className="card" key={`card_${skill.key}`}>
              <div className="card-image">{skillImageMap[skill.image]}</div>
              <div className="card-body">
                <h3>{skill.title}</h3>
                <p>{skill.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
