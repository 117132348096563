import React from "react";
import workData from "../../../data/work.json";

const WorkSection = () => {
  return (
    <section
      id="work-section"
      className="section section-span section-align-center"
    >
      <div className="container">
        <h2 className="text-align-center">Work history</h2>
        <div className="card-row">
          {workData.map((work) => (
            <div className="card" key={`card_work_${work.key}`}>
              <div className="card-body">
                <h3>{work.title}</h3>
                <strong>{work.role}</strong>
                <p>{work.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkSection;
