import React from "react";
import "./home.scss";
import AboutSection from "./sections/About";
import SkillsSection from "./sections/Skills";
import WorkSection from "./sections/Work";
import ProjectSection from "./sections/Projects";
import ResumeSection from "./sections/Resume";
import ContactSection from "./sections/Contact";

const HomeView = () => {
  return (
    <div id="home-view" className="view">
      <AboutSection />
      <SkillsSection />
      <WorkSection />
      <ProjectSection />
      <ResumeSection />
      <ContactSection />
    </div>
  );
};

export default HomeView;
