import React from "react";

const ContactSection = () => {
  return (
    <section
      id="contact-section"
      className="section section-dark section-align-center"
    >
      <div className="container padding-scalar-32 text-align-center">
        <p className="font-size-md font-code margin-scalar-0">
          If you have interesting work opportunities, feel free to reach out to
          me at
        </p>
        <a
          href="mailto:sarthak3gambhir@gmail.com"
          className="highlight-link font-size-md font-code display-block margin-scalar-y-32"
        >
          sarthak3gambhir@gmail.com
        </a>
      </div>
    </section>
  );
};

export default ContactSection;
