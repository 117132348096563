import { createBrowserRouter } from "react-router-dom";
import HomeView from "../views/Home";
import BlogView from "../views/Blog";
import ErrorHandler from "../components/ErrorHandler";
import ApplicationRoot from "../views/Root";

const applicationRouter = createBrowserRouter([
  {
    path: "/",
    element: <ApplicationRoot />,
    errorElement: <ErrorHandler />,
    children: [
      {
        path: "/",
        element: <HomeView />,
      },
      {
        path: "/blog",
        element: <BlogView />,
      },
    ],
  },
]);

export default applicationRouter;
