import React from "react";
import FlexGrid from "../../../components/FlexGrid";
import resumePreviewStatic from "../../../assets/images/resume-preview-static.png";
import resumePreviewInteractive from "../../../assets/images/resume-preview-interactive.png";

const ResumeSection = () => {
  const gridData = [
    {
      id: "resume-row",
      breakpoint: "lg",
      items: [
        {
          id: "static-resume",
          content: (
            <a
              href={
                process.env.PUBLIC_URL +
                "/resume/static/Resume - Sarthak Gambhir.pdf"
              }
              target="_blank"
              rel="noreferrer"
              className="plain-link"
            >
              <img
                src={resumePreviewStatic}
                alt="Static resume preview"
                className="resume-preview resume-preview-static"
              />
              <div className="text-align-center">
                <h3>Static</h3>
                <p>
                  For formal use or if you want to read more,
                  <br />a pdf version of my resume.
                </p>
              </div>
            </a>
          ),
        },

        {
          id: "interactive-resume",
          content: (
            <a
              href={
                "https://astrabound.itch.io/interactive-resume-sarthak-gambhir"
              }
              target="_blank"
              rel="noreferrer"
              className="plain-link"
            >
              <img
                src={resumePreviewInteractive}
                alt="Static resume preview"
                className="resume-preview resume-preview-static"
              />
              <div className="text-align-center">
                <h3>Interactive</h3>
                <p>If you have some time for fun, go through this!</p>
              </div>
            </a>
          ),
        },
      ],
    },
  ];

  return (
    <section
      id="resume-section"
      className="section section-span section-align-center"
    >
      <div className="container">
        <h2 className="text-align-center">Resume</h2>
        <FlexGrid gridData={gridData} />
      </div>
    </section>
  );
};

export default ResumeSection;
