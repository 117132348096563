import React from "react";
import "./FlexGrid.scss";

const FlexGrid = ({ gridData = [] }) => {
  return (
    <div className="flex-grid">
      {gridData?.map((gridRow) => (
        <div
          className={`flex-grid-row flex-grid-row-break-${gridRow.breakpoint}`}
          key={gridRow?.id}
        >
          {gridRow?.items?.map((gridRowItem) => (
            <div className="flex-grid-item" key={gridRowItem.id}>
              {gridRowItem?.headerContent && (
                <div className="flex-grid-item-header">
                  {gridRowItem?.headerContent}
                </div>
              )}

              {gridRowItem.content}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FlexGrid;
